<template>
    <div class="overflow-hidden bg-white py-24 sm:py-32">
        <div class="mx-auto max-w-7xl md:px-6 lg:px-8">
            <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                <div class="px-6 md:px-0 lg:pr-4 lg:pt-4">
                    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                        <p class="text-base font-semibold leading-7 text-primary">We staan erop</p>
                        <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Een stevig frame van staal</h2>
                        <p class="mt-6 text-lg leading-8 text-gray-600">We maken gebruik van stalen kwalitatieve frames zodat uw Standing desk stevig op de benen staat.</p>
                        <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            <div v-for="feature in features" :key="feature.name" class="relative">
                                <dt class="inline font-semibold text-lg text-gray-900">
                                    {{ feature.name }}
                                </dt>
                                <br>
                                <dd class="inline text-lg">{{ feature.description }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div class="sm:px-6 lg:px-0">
                    <div class="sm:mx-auto sm:max-w-2xl md:pt-16  lg:mx-0 lg:max-w-none">
                        <div class="mx-auto max-w-2xl sm:mx-0 sm:max-w-none px-4 md:px-0">
                            <img src="/images/frame.jpg" alt="Standing Desk frame" class="w-full rounded-xl sm:rounded-3xl " />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { WrenchScrewdriverIcon, SpeakerXMarkIcon, BoltIcon } from '@heroicons/vue/20/solid'

const features = [
    {
        name: 'Montage in 5 minuten',
        description: 'Het frame is vanuit de verpakking reeds geassembleerd.',
    },
    {
        name: 'Snelle stille motoren',
        description: 'Onze motoren verhogen en verlagen uw Standing Desk met 40mm per seconde.',
    },
    {
        name: 'Collision detection',
        description: 'Onze desks zijn standaard uitgerust met een anti Collision systeem.',
    },
]
</script>
