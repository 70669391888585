<template>
  <div class="py-12 sm:py-24 bg-white">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl sm:text-center">
        <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Een standing desk is
          zorgen voor jezelf</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600">Voorkom nek- en rugpijn met onze in hoogte
          verstelbare bureaus; te hoge tafels leiden tot opgetrokken schouders en ongemak, terwijl te lage
          tafels een bolle rug veroorzaken. Met eenvoudige hoogteverstelling wissel je naadloos tussen
          actief en ondersteund zitten.</p>
      </div>
    </div>
    <div class="relative overflow-hidden pt-16">
      <div class="mx-auto max-w-7xl px-6 lg:px-16">
        <img src="/images/officialsm.webp" alt="Standing desk screenshot"
             class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" width="2432" height="1442"/>
        <div class="relative" aria-hidden="true">
          <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"/>
        </div>
      </div>
    </div>
    <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
      <dl class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
        <div v-for="feature in features" :key="feature.name" class="relative pl-9">
          <dt class="inline font-semibold text-gray-900">
            <component :is="feature.icon" class="absolute left-1 top-1 h-5 w-5 text-primary"
                       aria-hidden="true"/>
            {{ feature.name }}
          </dt>
          <br>
          <dd class="inline">{{ feature.description }}</dd>
        </div>
      </dl>
    </div>
  </div>
</template>
<script setup>

import {ArrowPathIcon, FingerPrintIcon, HeartIcon} from "@heroicons/vue/20/solid/index.js";

const features = [
    {
        name: 'Verbeterde ergonomie door afwisseling',
        description: 'Standing desks maken het mogelijk om eenvoudig te wisselen tussen zitten en staan tijdens het werk.',
        icon: ArrowPathIcon,
    },
    {
        name: 'Vermindering van lichamelijke klachten',
        description: 'Regelmatig wisselen tussen staand en zittend werken kan leiden tot minder lichamelijke klachten en betere werkprestaties.',
        icon: HeartIcon,
    },
    {
        name: 'Gebruiksgemak en flexibiliteit',
        description: 'Met één druk op de knop jouw persoonlijke hoogte instellen. Handig wanneer meerdere mensen gebruik maken van de standing desk.',
        icon: FingerPrintIcon,
    },
]
</script>
