<script setup>
import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';

const page = usePage();
const appleAppStoreUrl = computed(() => page.props.appleAppStoreUrl);
const googlePlayStoreUrl = computed(() => page.props.googlePlayStoreUrl);
</script>

<template>
    <div class="bg-gradient-to-r from-neutral to-accent relative overflow-hidden">
        <div class="mx-auto max-w-7xl px-6 py-12 pb-24 sm:py-16 lg:px-8 grid grid-cols-1 gap-2 lg:grid-cols-3 ">
            <div class="lg:flex flex-col lg:items-left gap-8 justify-between mt-10 col-span-2">
                <h2 class="text-xl mb-2 font-bold tracking-tight text-white sm:text-2xl cambay-bold">Een werkplek die
                    zich
                    aanpast naar jou</h2>
                <p class="text-2xl font-bold tracking-tight text-white sm:text-3xl cambay">Transformeer jouw
                    werkplek via een traditionele controller of via onze app om je welzijn en productiviteit bevorderen.
                    We
                    combineren
                    innovatie met vakmanschap om elke werkruimte te optimaliseren.</p>
                <div class="flex gap-4 mt-4 justify-center lg:justify-start">
                    <a :href="appleAppStoreUrl" target="_blank" rel="nofollow"> <img
                        src="/images/apple-app-store.svg" class="h-[45px] px-4 py-2 border border-white rounded-lg"
                        alt="available on IOS"></a>
                    <a target="_blank" rel="nofollow"
                       :href="googlePlayStoreUrl">
                        <img src="/images/google-play-store.svg"
                             class="h-[45px] px-4 py-2 border border-white rounded-lg"
                             alt="available on Android">
                    </a>
                </div>
            </div>
            <img src="/images/app/mockup.webp" class="-mb-96 mt-10 mx-auto w-80 xl:w-96" alt="LevelUp app preview">
        </div>
    </div>
</template>
