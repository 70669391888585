<script setup>

</script>

<template>
    <div class="relative isolate overflow-hidden bg-gradient-to-b from-[#fafafc] to-[#fdfdfc]">
        <div class="mx-auto max-w-7xl px-6 pt-10 lg:flex lg:px-8 lg:pt-40">
            <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
                <h1 class="sm:mt-16 text-gray-900 xl:mt-16 mt-0 text-2xl sm:text-4xl cambay-bold">De standing desk die zich aanpast aan jou</h1>
                <p class="text-xl mt-6 leading-8 teaxt-gray-600">Ontworpen voor jouw welzijn, perfect op maat van jouw interieur.</p>
                <div class="mt-10 flex flex-col items-start gap-y-4">
                    <p class="text-md font-semibold leading-6 text-gray-900">Verzending binnen 14 dagen • app inbegrepen</p>
                    <a :href="route('configurator')" class="btn btn-outline btn-lg">Ontwerp jouw LevelUp desk<span aria-hidden="true">→</span></a>
                </div>
            </div>
            <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                    <div class="-m-2 rounded-xl  p-2 lg:-m-4 lg:rounded-2xl lg:p-4">
                        <video playsinline  autoplay muted src="/videos/render.mp4?v=6" width="2432" height="1442" class="w-[76rem] rounded-md"></video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
